export const A = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.232 0.199999V1.572L0.42 7.032V10H2.366V8.11L8.232 4.022V10H10.192V0.199999H8.232Z"
      fill="currentColor"
    />
  </svg>
);
