export const X = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.276 4.134L7.546 8.054V0.199999H4.508L0.42 6.08H2.856L5.586 2.16V10H8.624L12.726 4.134H10.276Z"
      fill="currentColor"
    />
  </svg>
);
