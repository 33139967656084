export const I = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.80057 10V8.04H0.85457V10H2.80057ZM3.29057 0.199999H0.85457L7.68657 10H10.1366L3.29057 0.199999Z"
      fill="currentColor"
    />
  </svg>
);
