export const G = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.42 0.199999V10H2.366V2.16H10.192V0.199999H0.42ZM4.326 6.094H8.232V10H10.192V4.134H4.326V6.094Z"
      fill="currentColor"
    />
  </svg>
);
