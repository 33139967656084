export const V = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.366 10V8.054H0.42V10H2.366ZM10.192 0.199999V7.2L5.306 0.199999H0.42V2.16H4.228L9.702 10H12.138V0.199999H10.192Z"
      fill="currentColor"
    />
  </svg>
);
