export const M = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.286 10V6.08H4.326V10H6.286ZM10.192 10V0.199999H8.232V10H10.192ZM0.42 10H2.366V2.16H4.326V4.12H6.286V0.199999H0.42V10Z"
      fill="currentColor"
    />
  </svg>
);
