export const J = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.366 2.16V0.199999H0.42V2.16H2.366ZM9.702 0.213999L4.228 8.054H0.42V10H5.306L10.192 3.014V10H12.138V0.213999H9.702Z"
      fill="currentColor"
    />
  </svg>
);
