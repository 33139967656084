export const Y = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.286 6.08V4.12H2.366V0.199999H0.42V6.08H6.286ZM8.232 0.199999V8.04H0.42V10H10.192V0.199999H8.232Z"
      fill="currentColor"
    />
  </svg>
);
