export const W = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.982 10V0.199999H8.036V10H9.982ZM2.366 8.054V0.199999H0.42V10H3.444L7.546 4.134H5.096L2.366 8.054Z"
      fill="currentColor"
    />
  </svg>
);
