export const L = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.366 2.16V0.199999H0.42V2.16H2.366ZM2.366 10V8.054H0.42V10H2.366ZM4.326 0.199999V2.16H8.232V8.04H4.326V10H10.192V0.199999H4.326Z"
      fill="currentColor"
    />
  </svg>
);
