export const P = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.42 0.199999V10H2.366V3.014L4.508 6.08H6.958L4.228 2.16H8.232V6.08H10.192V0.199999H0.42Z"
      fill="currentColor"
    />
  </svg>
);
