import { useInterval } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

type UseTextScrambleProps = {
  chars: any[];
  phrases: string[];
  stepTimeMilliseconds: number;
};

export const useTextScramble = ({
  chars,
  phrases,
  stepTimeMilliseconds,
}: UseTextScrambleProps) => {
  const [frameRequest, setFrameRequest] = useState<number | undefined>();
  const [, setCounter] = useState(0);
  const [value, setValue] = useState<any[]>(phrases[0].split(``));

  const update = useCallback(
    (queue: any[], frame = 0): void => {
      let complete = 0;

      const output = queue.map(({ from, to, start, end, Char }) => {
        if (frame >= end) {
          complete += 1;
          return to;
        }
        if (frame >= start) {
          return !Char || Math.random() < 0.28
            ? chars[Math.floor(Math.random() * chars.length)]
            : Char;
        }
        return from;
      });

      setValue(output);

      if (complete !== queue.length) {
        setFrameRequest(requestAnimationFrame(() => update(queue, frame + 1)));
      }
    },
    [chars],
  );

  const setText = useCallback(
    (oldText: string, newText: string) => {
      if (frameRequest) cancelAnimationFrame(frameRequest);

      const queue = Array.from(
        { length: Math.max(oldText.length, newText.length) },
        (v, i) => {
          const from = oldText[i] || ``;
          const to = newText[i] || ``;
          const start = Math.floor(Math.random() * 40);
          const end = start + Math.floor(Math.random() * 40);

          return { from, to, start, end };
        },
      );

      update(queue);
    },
    [frameRequest, update],
  );

  const tick = useCallback(() => {
    setCounter((c) => {
      const oldText = c <= 0 ? phrases[c] : phrases[c - 1];
      const newText = phrases[c];

      setText(oldText, newText);

      return (c + 1) % phrases.length;
    });
  }, [phrases, setText]);

  useEffect(() => {
    tick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(tick, stepTimeMilliseconds);

  return value;
};
