export const O = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.78 8.04L5.306 0.199999H0.42V2.16H4.228L8.33 8.04H0.42V10H12.152V8.04H10.78Z"
      fill="currentColor"
    />
  </svg>
);
