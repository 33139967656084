export const N = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.232 0.199999V8.04H4.326V10H10.192V0.199999H8.232ZM4.326 6.08H6.286V0.199999H0.42V10H2.366V2.16H4.326V6.08Z"
      fill="currentColor"
    />
  </svg>
);
