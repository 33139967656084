export const D = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.326 0.199999V2.16H8.232V8.04H4.326V10H10.192V0.199999H4.326ZM2.366 10V0.199999H0.42V10H2.366ZM6.286 6.08V4.12H4.326V6.08H6.286Z"
      fill="currentColor"
    />
  </svg>
);
