export const F = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.366 8.04V6.08H6.286V4.12H2.366V0.199999H0.42V10H10.192V8.04H2.366Z"
      fill="currentColor"
    />
  </svg>
);
