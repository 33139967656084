export const U = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.232 0.199999V8.04H5.278L2.366 6.024V0.199999H0.42V7.102L4.578 10H10.192V0.199999H8.232Z"
      fill="currentColor"
    />
  </svg>
);
