export const K = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.138 10V8.054H10.192V10H12.138ZM7.252 0.199999L2.366 7.2V0.199999H0.42V10H2.856L8.33 2.16H12.138V0.199999H7.252Z"
      fill="currentColor"
    />
  </svg>
);
