export const Z = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.192 2.16V0.199999H8.232V2.16H10.192ZM3.346 0.199999H0.896L6.384 8.04H2.366V4.134H0.42V10H10.192L3.346 0.199999Z"
      fill="currentColor"
    />
  </svg>
);
