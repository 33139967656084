export const Q = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.152 10V8.04H0.42V10H12.152ZM6.958 6.08H9.408L5.306 0.199999H0.42V2.16H4.228L6.958 6.08Z"
      fill="currentColor"
    />
  </svg>
);
