export const H = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.152 2.16V0.199999H0.42V2.16H12.152ZM9.408 4.134H6.958L4.228 8.04H0.42V10H12.152V8.04H6.678L9.408 4.134Z"
      fill="currentColor"
    />
  </svg>
);
