export const T = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.152 6.08V0.199999H10.192V6.08H12.152ZM2.366 6.08V0.199999H0.42V6.08H2.366ZM7.252 0.199999H5.306V8.04H0.42V10H12.152V8.04H7.252V0.199999Z"
      fill="currentColor"
    />
  </svg>
);
