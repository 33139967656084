export const E = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.366 2.16V0.199999H0.42V2.16H2.366ZM10.192 6.094V4.134H6.958L9.702 0.199999H7.252L0.42 10H10.192V8.04H4.228L5.586 6.094H10.192Z"
      fill="currentColor"
    />
  </svg>
);
