export const R = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.192 6.08V0.199999H0.42V10H2.366V3.014L7.252 10H9.702L4.228 2.16H8.232V6.08H10.192Z"
      fill="currentColor"
    />
  </svg>
);
